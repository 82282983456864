import React from 'react'
import { Link } from 'gatsby'

import Footer from '@/components/Footer.js'
import logo from '@/images/logo.svg'
import componentRenderer, {
  PostLayout,
  PostHeroBlock,
  PostAuthorFooterBlock,
} from '@/components/postPageComponents'
import { vwpx } from '@/utils'

const Post = ({
  pageContext: {
    blocks: [hero, ...blocks],
    accentColor,
    authors,
    slug,
  },
}) => (
  <PostLayout accentColor={accentColor} id={slug}>
    <Link
      to="/"
      css={{
        position: 'fixed',
        zIndex: 3,
        top: 0,
        left: 15,
        width: vwpx(85),
        mixBlendMode: 'overlay',
      }}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: vwpx(60),
          margin: '20px 0',
          '@media(max-width: 500px)': {
            height: vwpx(80, 'vw', 500),
            width: vwpx(120, 'vw', 500),
          },
        }}
      >
        <img
          src={logo}
          alt="Fondof logo"
          css={{
            width: vwpx(45),
            height: vwpx(30),
            display: 'block',
            '@media(max-width: 500px)': {
              width: vwpx(150),
              height: vwpx(105),
            },
          }}
        />
      </div>
    </Link>
    <PostHeroBlock {...hero} authors={authors} />

    {blocks.map(block => componentRenderer(block))}

    {authors && <PostAuthorFooterBlock authors={authors} />}

    <Footer css={{ width: '100vw' }} onVisibilityChange={() => {}} />
  </PostLayout>
)

export default Post
