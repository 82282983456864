import React, { Fragment } from 'react'
import ReactMarkdown from 'react-markdown'
import Layout, { Composition, Box } from 'atomic-layout'
import ImageFadeIn from 'react-image-fade-in'

import { fluidFontSize, vwpx } from '@/utils'

const horizontalPagePadding = vwpx(120)
const rowGap = 30

Layout.configure({
  defaultBreakpointName: 'desktop',
  defaultBehavior: 'down',
  breakpoints: {
    mobile: {
      maxWidth: 500,
    },
    desktop: {
      minWidth: 500,
    },
  },
})

const Grid = props => (
  <Composition
    templateCols={`
    [full-width-start] ${horizontalPagePadding}
    [left-content-start] 1fr
    [left-content-end gutter-start] calc(${horizontalPagePadding} / 2)
    [gutter-end right-content-start] 1fr
    [right-content-end] ${horizontalPagePadding}
    [full-width-end]
  `}
    autoRows={`minmax(${rowGap * 2}px, auto)`}
    autoRowsMobile={`minmax(${rowGap + 10}px, auto)`}
    gapRow={rowGap}
    gapRowMobile={rowGap / 2}
    {...props}
  />
)

const LeftContent = props => (
  <Box
    css={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    colStart="left-content-start"
    colEnd="left-content-end"
    colStartMobile="left-content-start"
    colEndMobile="right-content-end"
    {...props}
  />
)

const RightContent = props => (
  <Box
    css={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    colStart="right-content-start"
    colEnd="right-content-end"
    colStartMobile="left-content-start"
    colEndMobile="right-content-end"
    {...props}
  />
)

const FullInner = props => (
  <Box
    css={{
      display: 'flex',
      position: 'relative',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    colStart="left-content-start"
    colEnd="right-content-end"
    {...props}
  />
)
export const FullWidth = ({ useAccentColorAsBackgroundColor, ...props }) => (
  <Box
    as={Grid}
    css={{
      backgroundColor: useAccentColorAsBackgroundColor && 'var(--secondary)',
      padding: '10em 0',
    }}
    colStart="full-width-start"
    colEnd="full-width-end"
    {...props}
  />
)

export const PostLayout = ({ accentColor, ...props }) => (
  <Box
    as={Grid}
    css={{
      1366: 2560,
      overflow: 'hidden',
      width: '100vw',
      minHeight: '100vh',
      margin: '0 auto',
      '--primary': accentColor.primary,
      '--secondary': accentColor.secondary,
      '--tertiary': accentColor.tertiary,
    }}
    {...props}
  />
)

export const PostHeroBlock = ({
  image,
  category,
  headline: { headline },
  authors,
}) => (
  <FullWidth
    css={{
      '@media(max-width: 500px)': {
        backgroundColor: 'transparent',
        boxShadow: `0 0 0 ${rowGap}px var(--secondary)`,
      },
    }}
  >
    <LeftContent css={{ zIndex: 1 }}>
      <PostImageItem image={image} />
    </LeftContent>

    <RightContent
      css={{
        minHeight: 'calc(90vh - 240px)',
        backgroundColor: 'var(--secondary)',
        boxShadow: `0 0 0 ${60 * 2}px var(--secondary)`,
        '@media(max-width: 500px)': {
          backgroundColor: 'var(--secondary)',
          boxShadow: `0 0 0 ${65 * 2}px var(--secondary)`,
        },
      }}
    >
      <div
        className="postPageHero"
        css={{
          zIndex: 3,
          position: 'absolute',
          bottom: '-100px',
          left: 0,
          fontSize: fluidFontSize(14),
          fontFamily: 'Circular',
          fontWeight: 'normal',
          color: '#fff',
          textAlign: 'center',
          transform: `translate(
              calc(-50% + (-${vwpx(60)} / 2)), 
              calc(-75px / 2)
            )`,
          '@media(max-width: 500px)': {
            left: '50%',
            transform: 'translateX(-50%)',
          },
        }}
      >
        scrollen
      </div>
      <div>{category}</div>

      <ReactMarkdown
        renderers={{
          paragraph: ({ children }) => (
            <div
              css={{
                fontFamily: 'GTSuperDisplayLight',
                fontSize: fluidFontSize(80),
                lineHeight: 1.1,
                textAlign: 'center',
                '@media(max-width: 500px)': {
                  fontSize: vwpx(43, 500),
                },
              }}
            >
              {children}
            </div>
          ),
          strong: ({ children }) => (
            <span
              css={{
                fontFamily: 'Circular',
                fontWeight: 600,
                fontSize: fluidFontSize(80),
                lineHeight: 1.1,
                textAlign: 'center',
                '@media(max-width: 500px)': {
                  fontSize: vwpx(43, 500),
                },
              }}
            >
              {children}
            </span>
          ),
        }}
        source={headline}
      />
      {authors && (
        <div>
          {authors.map(({ id, contribution, employee: { name } }) => (
            <span css={{ '& + &': { marginLeft: 10 } }} key={id}>
              {contribution.join(', ')} {name}
            </span>
          ))}
        </div>
      )}
    </RightContent>
  </FullWidth>
)

export const PostLeftRightBlock = ({
  useAccentColorAsBackgroundColor,
  items: [left, right],
  type,
}) => (
  <FullWidth
    useAccentColorAsBackgroundColor={useAccentColorAsBackgroundColor}
    className={type}
  >
    <LeftContent css={{ fontSize: fluidFontSize(100) }}>
      {componentRenderer(left)}
    </LeftContent>
    <RightContent
      css={{
        fontSize: fluidFontSize(100),
        '@media(max-width: 500px)': {
          marginTop: '1.5em',
        },
      }}
    >
      {componentRenderer(right)}
    </RightContent>
  </FullWidth>
)

export const PostAuthorFooterBlock = ({ authors }) => (
  <FullWidth
    css={{
      padding: `${rowGap * 2}px 0px`,
      backgroundColor: 'var(--secondary)',
    }}
  >
    <FullInner
      css={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        display: 'flex',

        '@media (max-width: 500px)': {
          width: '100%',
        },
      }}
    >
      {authors &&
        authors.map(
          ({
            id,
            contribution,
            employee: { name, department, bio, image },
          }) => (
            <div
              key={id}
              css={{
                display: 'flex',
                alignItems: 'center',
                '&': {
                  marginLeft: 40,
                  '@media (max-width: 500px)': {
                    flexWrap: 'wrap',
                    marginLeft: 0,
                  },
                },
              }}
            >
              <div
                css={{
                  width: 230,
                  marginRight: 20,
                  '@media (max-width: 500px)': {
                    width: '100%',
                    marginLeft: 20,
                  },
                }}
              >
                <PostImageItem
                  image={image}
                  css={{
                    filter: 'grayscale(1) contrast(1.3)',
                    mixBlendMode: 'multiply',

                    '@media (max-width: 500px)': {
                      paddingBottom: 20,
                    },
                  }}
                />
              </div>

              <div
                css={{
                  maxWidth: '20vw',
                  marginLeft: 20,
                }}
              >
                {contribution.join(', ')} {name}
                <div
                  css={{
                    fontSize: fluidFontSize(30),
                    marginTop: 20,
                  }}
                >
                  <div
                    css={{
                      fontFamily: 'Circular',
                      fontWeight: 600,
                      '@media(max-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    FOND OF
                  </div>
                  <div
                    css={{
                      fontFamily: 'GTSuperDisplayLight',
                      '@media(max-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    {department}
                  </div>
                  <div
                    css={{
                      marginTop: 20,
                      fontFamily: 'GTSuperDisplayLight',
                      '@media(max-width: 500px)': {
                        display: 'none',
                      },
                    }}
                  >
                    {bio}
                  </div>
                </div>
              </div>
            </div>
          )
        )}
    </FullInner>
  </FullWidth>
)

const alignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
}

export const PostDividerBlock = ({ alignment }) => (
  <FullWidth
    css={{
      display: 'flex',
      justifyContent: alignmentMap[alignment],
      padding: 0,
    }}
  >
    <div
      css={{
        height: 3,
        width: vwpx(500),
        backgroundColor: 'var(--primary)',
      }}
    ></div>
  </FullWidth>
)

export const PostLeftRightOverlappingImageBlock = ({
  images: [firstImage, secondImage],
  alignment,
  ...props
}) => {
  const isLeft = alignment === 'left'

  return (
    <FullWidth
      css={{
        '@media(max-width: 500px)': {
          transform: 'translateY(-15%)',
        },
      }}
    >
      <Box
        as={PostImageItem}
        css={{
          display: 'flex',
          justifyContent: 'center',
          gridRowStart: 2,
          gridColumnStart: 'left-content',
          gridColumnEnd: 'right-content',
        }}
        rowStart={1}
        colStart={isLeft ? 'left-content-start' : 'gutter-end'}
        colEnd={isLeft ? 'gutter-start' : 'right-content-end'}
        justify={isLeft ? 'flex-end' : 'flex-start'}
        colStartMobile="left-content-start"
        colEndMobile="right-content-end"
        image={firstImage}
        {...props}
      />
      <div
        css={{
          zIndex: 1,
          position: 'relative',
          gridRowStart: 1,
          gridColumnStart: 'gutter-start',
          gridColumnEnd: 'gutter-end',
          width: '33.33vw',
          transform: 'translateX(-50%) translateY(25%)',
          '@media(max-width: 500px)': {
            transform: 'translateX(0%)',
            width: '90vw',
            top: '40%',
            left: '5%',
            display: 'block',
            gridColumnStart: 'full-width-start',
            gridColumnEnd: 'full-width-end',
          },
        }}
      >
        <PostImageItem
          css={{
            '@media(max-width: 500px)': {
              width: '100%',
            },
          }}
          image={secondImage}
        />
      </div>
    </FullWidth>
  )
}

export const PostTextItem = ({ textItem: { text } }) => (
  <ReactMarkdown
    renderers={{
      paragraph: ({ children }) => (
        <div
          css={{
            '& > p': {
              marginBottom: '-1em',
            },
            '& > a': {
              fontWeight: 900,
              color: 'black',
            },
            marginBottom: '1.5em',
          }}
        >
          {children}
        </div>
      ),
      root: ({ children }) => (
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            fontFamily: 'Circular',
            lineHeight: 1.6,
            fontSize: fluidFontSize(14),
            fontWeight: 600,

            '@media(max-width: 500px)': {
              fontSize: fluidFontSize(16, 500),
              maxWidth: '100%',
            },
          }}
        >
          {children}
        </div>
      ),
      emphasis: ({ children }) => (
        <p
          css={{
            marginTop: 0,
            fontFamily: 'GTSuperDisplayRegular',
            lineHeight: 1.6,
            fontSize: fluidFontSize(26),

            '@media(max-width: 500px)': {
              fontSize: fluidFontSize(20, 500),
              maxWidth: '100%',
            },
          }}
        >
          {children}
        </p>
      ),
      strong: ({ children }) => (
        <p
          css={{
            fontFamily: 'Circular',
            lineHeight: 1.6,
            fontSize: fluidFontSize(15),
            fontWeight: 900,
            marginTop: 0,
            marginBottom: '0.5em',

            '@media(max-width: 500px)': {
              fontSize: fluidFontSize(16, 500),
              maxWidth: '100%',
            },
          }}
        >
          {children}
        </p>
      ),
    }}
    source={text}
  />
)

export const PostTextBlock = ({ text: { text } }) => (
  <FullWidth
    css={{
      '@media(max-width: 500px)': {
        padding: '3em 0 0 0',
      },
    }}
  >
    <FullInner
      css={{
        fontFamily: 'GTSuperDisplayLight',
        lineHeight: 1.2,
        fontSize: fluidFontSize(48),
        '@media(max-width: 500px)': {
          fontSize: fluidFontSize(13),
        },
      }}
    >
      {text}
    </FullInner>
  </FullWidth>
)

export const PostQuoteItem = ({ text, attribution }) => (
  <Fragment>
    <div
      css={{
        fontFamily: 'GTSuperDisplayRegular',
        fontSize: fluidFontSize(36),
        lineHeight: 1.2,
        textAlign: 'center',
        quotes: `
          "»" "«"
        `,
        '&::before': {
          content: 'open-quote',
        },
        '&:after': {
          content: 'close-quote',
        },
        '@media(max-width: 500px)': {
          fontSize: vwpx(25, 500),
        },
      }}
    >
      {text}
    </div>

    <div
      css={{
        fontFamily: 'Circular',
        lineHeight: 1.6,
        fontSize: fluidFontSize(12),
        textAlign: 'center',
        marginTop: rowGap * 0.3,
        fontWeight: 600,
        '&::before': {
          position: 'absolute',
          content: '"―"',
          transform: 'translateX(calc(-100% - 3px))',
        },
        '@media(max-width: 500px)': {
          fontSize: fluidFontSize(10, 500),
        },
      }}
    >
      {attribution}
    </div>
  </Fragment>
)

export const PostImageItem = ({
  image: {
    description,
    image: {
      fluid: { src, sizes },
    },
  },
  ...props
}) => (
  <Box {...props}>
    <ImageFadeIn
      css={{ maxWidth: '100%', display: 'block' }}
      src={src}
      sizes={sizes}
      alt={description}
    />
  </Box>
)

const componentMap = {
  PostLeftRightBlock,
  PostTextItem,
  PostImageItem,
  PostQuoteItem,
  PostLeftRightOverlappingImageBlock,
  PostDividerBlock,
  PostTextBlock,
}

const componentRenderer = ({ __typename, id, ...props }) => {
  const type = __typename.replace('Contentful', '')

  if (!type) {
    console.warn('missing typename for', {
      id,
      ...props,
    })
    return null
  }

  if (!componentMap[type]) {
    console.warn('missing component for', type)
    return <FullWidth>{type}</FullWidth>
  }

  const Component = componentMap[type]

  return <Component key={id} {...props} type={type} />
}

export default componentRenderer
